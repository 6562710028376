<template>
	<div class="content">
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg" />
		<div class="content-content jucenter	">
			<div class="content-content-left">
				<div class="menus-title">{{menusTitle}}</div>
				<div class="menus-list">
					<div class="menus-list-item flex-layout" v-for="(item,index) in menuList" :key="index"
						:style="{color:item.id == menusAcId?'#008AD2':''}" @click="menusChange(item.id)">
						<div>{{item.title}}</div>
						<img src="../../assets/image/ic_erjjtblue.png" v-if="item.id == menusAcId" />
						<img src="../../assets/image/ic_erjjtgray.png" v-else />
					</div>
				</div>
			</div>
			<div class="content-content-right">
				<div class="content-title">
					{{brief}}
				</div>
				<div class="content-right-firstFloor" v-for="(item1,index1) in list" :key="index1">
					<div class="firstFloor-title">
						{{item1.title}}
					</div>
					<div class="content-right-secondFloor">
						<div class="secondFloor-item flex" v-for="(item2,index2) in item1?.child" :key="index2">
							<div class="secondFloor-title">
								{{item2.title}}
							</div>
							<ul class="content-right-thirdFloor">
								<li v-for="(item3,index3) in item2?.child" :key="index3">
									{{item3.title}}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		ref,
		getCurrentInstance,
	} from 'vue';
	import {
		useRoute
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"

	const router = useRoute()
	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", router.query.type);
		getTopimg()
		getmenus()
	})

	const typeTitle = ref([{
			title: 'Services',
			type: 2
		},
		{
			title: 'SAIFU Solutions',
			type: 3
		},
	])

	/**
	 * 左侧菜单
	 */
	const menusTitle = ref('')

	const menusAcId = ref('')

	const menuList = ref([])

	async function getmenus() {
		let res = await proxy.$axios.get(router.query.type == 2 ? '/api/index/getServicesData' :
			'/api/index/getSolutionsData', {
				pid: 0
			})
		menuList.value = res.data
		menusChange(router.query.id)
	}

	const list = ref({})

	async function menusChange(id) {
		menusAcId.value = id?id : menuList.value[0].id
		menusTitle.value = typeTitle.value.find(v => v.type == router.query.type).title
		if(id){
			brief.value = menuList.value.find(v => v.id == id).brief
		}else{
			brief.value = menuList.value[0].brief
		}
		
		let res = await proxy.$axios.get(router.query.type == 2 ? '/api/index/getServicesData' :
			'/api/index/getSolutionsData', {
				pid: id?id : menuList.value[0].id
			})
		list.value = res.data
	}
	/**
	 * 右侧头部文字
	 */
	const brief = ref('')

	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: router.query.type == 2 ? 'services' : 'solutions'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg {
		width: 100%;
		height: auto;
		margin-top: 0.05rem;
		display: flex;
	}

	.content-content {
		/* background: url('../../assets/image/bg_neiynrbj.png') repeat; */
		background-size: 100% auto;
		padding: 0.19rem 0;
	}

	.content-content-left {
		padding-right: 0.16rem;
	}

	.menus-title {
		font-weight: bold;
		font-size: 0.13rem;
	}

	.menus-list {
		border-top: 1px solid #eeeeee;
		margin-top: 0.1rem;
	}

	.menus-list-item {
		font-size: 0.08rem;
		font-weight: 400;
		/* text-align: right; */
		width: 1.52rem;
		margin-top: 0.12rem;
		cursor: pointer;
	}

	.menus-list-item>img {
		width: 0.09rem;
		height: 0.09rem;
		
		margin-left: 0.12rem;
	}

	.content-content-right {
		padding-left: 0.19rem;
		border-left: 1px solid #E6E6E6;
	}

	.content-content-right .content-title {
		font-size: 0.07rem;
		color: #333333;
		line-height: 0.11rem;
		padding-top: 0.07rem;
		width: 2.93rem;
	}

	.content-right-firstFloor {
		margin-top: 0.18rem;
		padding-bottom: 0.11rem;
		border-bottom: 1px solid #E6E6E6;
		font-weight: bold;
		font-size: 0.09rem;
		color: #000000;
		line-height: 0.11rem;
	}

	.firstFloor-title {
		padding-bottom: 0.11rem;
		border-bottom: 1px solid #E6E6E6;
		width: 3.2rem;
	}

	.content-right-secondFloor {
		display: grid;
		/* grid-template-columns: repeat(1, 1.36rem); */
		grid-template-columns: repeat(1, 2.8rem);
		grid-gap: 0 0.19rem;
		grid-auto-rows: auto;
	}

	.secondFloor-item {
		font-size: 0.09rem;
		line-height: 0.09rem;
		padding: 0.12rem 0;
	}

	.secondFloor-title {
		font-weight: 500;
		width: 0.65rem;
	}

	.content-right-thirdFloor {
		margin-left: 0.35rem;
		flex: 1;
	}

	.content-right-thirdFloor>li {
		list-style-type: disc;
		margin-bottom: 0.06rem;
		font-weight: 400;
		font-size: 0.1rem;
		line-height: 0.12rem;
		color: #666666;
	}
</style>